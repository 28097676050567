import * as React from "react"
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image";
import '../styles/styles.scss'
import {Parallax, ParallaxBanner} from "react-scroll-parallax";
import {Link} from "gatsby";

const ServicioDigitalizacionPage = () => {
    return (
        <Layout>
            <div className={'slider cover-simple servicios'}>
                <div className={'item'}>
                    <div className={'sliderleft'}>
                        <p className={'especial3 especialservicios'}>SERVICIOS</p>
                        <h3 className={'h3servicios'}>Digitalización y Automatización de Procesos</h3>
                        <p className={'contenido1 contenidoservicios'}>Implica adaptarse al nuevo entorno tecnológico y
                            representa una ventaja competitiva adicional para todas las empresas.</p>
                    </div>
                    <div className={'sliderright'}>
                        <StaticImage src="../images/digitalizacion-slider-1.png" alt="T.I.O. ALEMAR"/>
                    </div>

                </div>
            </div>

            <div className={'image-info process bigger'}>
                <Parallax speed={-10} className={'bloqueleft40 float left'}>
                    <StaticImage className={'img'} src="../images/digitalizacion-imgleft1.png" alt="Servicios ALEMAR"/>
                </Parallax>
                <Parallax speed={10} className={'bloqueright2 right bloqueright2servicios '}>
                    <h4 className={'h4servicios'}>Automatización de cualquier proceso empresarial. Con
                        la <span className={'color-pink'}>digitalización</span> de documentos físicos e información electrónica, <span
                            className={'color-pink'}>facilitamos</span> la administración
                        y extracción de información.</h4>
                    <p className={'especial2'}>Te brindamos una conexión directa entre tu información y actividades de
                        negocio.</p>
                </Parallax>
            </div>

            <div className={'wrapperbase quote'}>
                <h1 data-sal="slide-right"
                    data-sal-duration={"600"}>
                    <span className="color-pink">Digitaliza</span> tus documentos y ahorra tiempo.
                </h1>
            </div>

            <div className={'Home4valores valoresservicios'}>
                <div className={'HomeCard CardServicios'}
                     data-sal="slide-up"
                     data-sal-delay="100"
                     data-sal-easing="ease">
                    <StaticImage className={'img'} src="../images/servicios-rapidez.png" alt="ALEMAR"/>
                    <p className={'especial3'}>Rapidez</p>
                    <p className={'contenido1'}>Mejore la productividad de los procesos y capture rápidamente
                        información basada en papel.</p>
                </div>
                <div className={'HomeCard CardServicios'}
                     data-sal="slide-up"
                     data-sal-delay="200"
                     data-sal-easing="ease">
                    <StaticImage className={'img'} src="../images/servicios-eficacia.png" alt="ALEMAR"/>
                    <p className={'especial3'}>Eficacia</p>
                    <p className={'contenido1'}>Incremento de la productividad en procesos. Así como control y
                        transparencia de datos.</p>
                </div>
                <div className={'HomeCard CardServicios'}
                     data-sal="slide-up"
                     data-sal-delay="300"
                     data-sal-easing="ease">
                    <StaticImage className={'img'} src="../images/servicios-integracion.png" alt="ALEMAR"/>
                    <p className={'especial3'}>Integración</p>
                    <p className={'contenido1'}>Administración de contenido y flujos de trabajo.</p>
                </div>
            </div>

            <div className="servicios-map">
                <StaticImage src={'../images/digitalizacio_image.png'} alt={'Digitalización'}/>
                <div className="descriptions">
                    <div className="description legal" data-sal="slide-up"
                         data-sal-delay="300"
                         data-sal-easing="ease">
                        <span className={'bottom'}></span>
                        <p className='especial3'>Area Legal</p>
                        <p className='especial3'>Administración de contratos</p>
                    </div>
                    <div className="description rh" data-sal="slide-up"
                         data-sal-delay="300"
                         data-sal-easing="ease">
                        <span className={'bottom'}></span>
                        <p className='especial3'>Recursos Humanos</p>
                        <p className='especial3'>Gestión de personal</p>
                    </div>
                    <div className="description atencion" data-sal="slide-up"
                         data-sal-delay="300"
                         data-sal-easing="ease">
                        <span className={''}></span>
                        <p className='especial3'>Atención al cliente</p>
                        <p className='especial3'>Expediente único de clientes</p>
                    </div>
                    <div className="description financiera" data-sal="slide-up"
                         data-sal-delay="300"
                         data-sal-easing="ease">
                        <span className={''}></span>
                        <p className='especial3'>Área financiera</p>
                        <p className='especial3'>Cuentas por cobrar</p>
                    </div>
                </div>
            </div>

            <hr className={'hrsolo'}></hr>

            <div className={'logossocios'}>
                <p className={'especial2 logosclientes logosaliados'}>Tenemos <span>Aliados</span> que nos ayudan a
                    gestionar los procesos.</p>
                <ul>
                    <li data-sal="slide-up"
                        data-sal-delay="100"
                        data-sal-easing="ease"><StaticImage src="../images/Lexmark.png" alt="Lexmark logo"/></li>
                    <li data-sal="slide-up"
                        data-sal-delay="200"
                        data-sal-easing="ease"><StaticImage src="../images/aws_logo.png" alt="AWS Logo"/></li>
                    <li data-sal="slide-up"
                        data-sal-delay="300"
                        data-sal-easing="ease"><StaticImage src="../images/Kodak_Alaris.png" alt="Kodak Logo"/></li>
                    <li data-sal="slide-up"
                        data-sal-delay="400"
                        data-sal-easing="ease"><StaticImage src="../images/M-Files-Logo-.png" alt="M Files Logo"/></li>
                    <li data-sal="slide-up"
                        data-sal-delay="500"
                        data-sal-easing="ease"><StaticImage src="../images/DOQSOFT.png" alt="DOQSOFT Logo"/></li>
                </ul>
            </div>

            <ParallaxBanner
                layers={[{
                    speed: 30,
                    children: (
                        <StaticImage src="../images/bg-digitalizacion.png" alt="Home ALEMAR"/>
                    )
                }]}
                className="bg-parallax"
            />
            <div className={'servicios-digital'}>
                <h3 className={'left'} data-sal="slide-right"
                    data-sal-duration={"600"}>Con el contenido electrónico y la digitalización de documentos, ten el
                    control de tu información.
                </h3>
                <div className={'right'}>
                    <p className={'especial2 pnosotros1 pservicios'}>¿No te gustaría tener todos tus expedientes en
                        digital y poder dar el seguimiento de manera más fácil?</p>
                    <hr></hr>
                    <ul>
                        <li>Captura</li>
                        <li>Distribución</li>
                        <li>Acceso</li>
                        <li>Almacenamiento</li>
                        <li>Gestión</li>
                        <li>Integración</li>
                        <li>Conexión directa y sin esfuerzo con tus sistemas empresariales</li>
                    </ul>
                </div>
            </div>


            <div className="wrapperbase infoblocks" id={'main-info'}>
                <div className={'infoblock'}>
                    <div className={'left'} data-sal="slide-right"
                         data-sal-duration={"600"}>
                        <StaticImage className={'img'} src="../images/dibujo-digitalizacion-colaboracion.png"
                                     alt="Servicios ALEMAR"/>
                    </div>
                    <div className={'right'} data-sal="slide-left" data-sal-delay={"200"}
                         data-sal-duration={"600"}>
                        <div className={'CardsServicios'}>
                            <div className="content">
                                <p className={'especial1'}>Colaboración</p>
                                <p className={'contenido1'}>Contamos con una solución inteligente de administración de
                                    información con la capacidad de conectarse a las carpetas y sistemas empresariales
                                    existentes.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'infoblock'}>
                    <div className={'left'} data-sal="slide-right"
                         data-sal-duration={"600"}>
                        <StaticImage className={'img'} src="../images/dibujo-digitalizacion-seguridad.png"
                                     alt="Servicios ALEMAR"/>
                    </div>
                    <div className={'right'} data-sal="slide-left" data-sal-delay={"200"}
                         data-sal-duration={"600"}>
                        <div className="CardsServicios">
                            <div className="content">
                                <p className={'especial1'}>Seguridad de información</p>
                                <p className={'contenido1'}>Nuestras soluciones cumplen con estándares de la industria,
                                    incluyendo almacenamiento de forma segura y cumplimiento de normas
                                    internacionles. </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'infoblock'}>
                    <div className={'left'} data-sal="slide-right"
                         data-sal-duration={"600"}>
                        <StaticImage className={'img'} src="../images/dibujo-digitalizacion-nube.png"
                                     alt="Servicios ALEMAR"/>
                    </div>
                    <div className={'right'} data-sal="slide-left" data-sal-delay={"200"}
                         data-sal-duration={"600"}>
                        <div className={'CardsServicios'}>
                            <div className="content">
                                <p className={'especial1'}>Servicios en sitio, nube, híbrido</p>
                                <p className={'contenido1'}>Administre y automatice el manejo de su informaicón digital
                                    con
                                    cualquier opción de infraestructura.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Layout>
    )
}

export default ServicioDigitalizacionPage

export const Head = () => {
    return (
        <>
            <title>Implementa las ventajas de la digitalización y automatización en tu negocio</title>
            <meta name="description" content="Automatiza cualquier proceso empresarial dentro de tu negocio a través de la digitalización de documentos y la información electrónica"/>
        </>
    )
}
